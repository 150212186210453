"use strict";
// available Languages in App
// hint: update botstrap.ts lang File to be loaded
Object.defineProperty(exports, "__esModule", { value: true });
exports.SecurityAttributeScope = exports.PubSubEventTypeMH = exports.CountryCode = exports.Locale = void 0;
// ******** Array liveLangs in packages/app/src/controls/commonControls.tsx updaten um Sprache freizuschalten *****
var Locale;
(function (Locale) {
    Locale["German"] = "de";
    Locale["English"] = "en";
    Locale["Polish"] = "pl";
    Locale["Czech"] = "cz";
    Locale["Hungarian"] = "hu";
    Locale["Romanian"] = "ro";
    Locale["French"] = "fr";
})(Locale || (exports.Locale = Locale = {}));
var CountryCode;
(function (CountryCode) {
    CountryCode["AT"] = "\u00D6sterreich";
    CountryCode["PL"] = "Polen";
    CountryCode["DE"] = "Deutschland";
    CountryCode["CZ"] = "Tschechien";
    CountryCode["HU"] = "Ungarn";
    CountryCode["HR"] = "Kroatien";
    CountryCode["SI"] = "Slowenien";
    CountryCode["SK"] = "Slowakei";
    CountryCode["RO"] = "Rum\u00E4nien";
    CountryCode["FR"] = "Frankreich";
})(CountryCode || (exports.CountryCode = CountryCode = {}));
var PubSubEventTypeMH;
(function (PubSubEventTypeMH) {
    PubSubEventTypeMH["carChanged"] = "carChanged";
    PubSubEventTypeMH["pendingAuctionChanged"] = "pendingAuctionChanged";
    PubSubEventTypeMH["invoicesGenerated"] = "invoicesGenerated";
})(PubSubEventTypeMH || (exports.PubSubEventTypeMH = PubSubEventTypeMH = {}));
var SecurityAttributeScope;
(function (SecurityAttributeScope) {
    SecurityAttributeScope[SecurityAttributeScope["min"] = 0] = "min";
    SecurityAttributeScope[SecurityAttributeScope["max"] = 1] = "max";
})(SecurityAttributeScope || (exports.SecurityAttributeScope = SecurityAttributeScope = {}));
