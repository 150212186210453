"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EXTRA_FEE_BELOW_500_PRICING_TABLE = exports.EXTRA_FEE_MIN_PRICING_TABLE = exports.EXTRA_FEE_MAX_PRICING_TABLE = exports.BUNDLE_FROM_PRICING_TABLE = exports.BUNDLE_MAX_FEE_PRICING_TABLE = exports.BUNDLE_FEE_PRICING_TABLE = exports.PLATTFORM_FEE_PRICING_TABLE = exports.BUNDLE_PERCENTAGE = exports.EXTRA_FEE_PERCENTAGE = exports.AuctionListTypes = exports.ReminderTypes = exports.AuctionType = exports.ProspectState = exports.AuctionSoldState = exports.AuctionState = exports.BID_CONFIG = exports.DUTCH_AUCTION_CONFIG = void 0;
exports.calculateAuctionFees = calculateAuctionFees;
exports.DUTCH_AUCTION_CONFIG = {
    STEP_DURATION: 15, // seconds
    MAX_DURATION: 360, // minutes
    MIN_DURATION: 15, // minutes
};
exports.BID_CONFIG = {
    BID_STEP_AMOUNT: 25,
    HAMMERTIME_DURATION: 30, // seconds
};
var AuctionState;
(function (AuctionState) {
    AuctionState[AuctionState["created"] = 0] = "created";
    AuctionState[AuctionState["started"] = 1] = "started";
    AuctionState[AuctionState["ended"] = 2] = "ended";
    AuctionState[AuctionState["canceled"] = 3] = "canceled";
})(AuctionState || (exports.AuctionState = AuctionState = {}));
var AuctionSoldState;
(function (AuctionSoldState) {
    AuctionSoldState[AuctionSoldState["open"] = 0] = "open";
    AuctionSoldState[AuctionSoldState["sold"] = 1] = "sold";
    AuctionSoldState[AuctionSoldState["notSold"] = 2] = "notSold";
    AuctionSoldState[AuctionSoldState["running"] = 3] = "running";
})(AuctionSoldState || (exports.AuctionSoldState = AuctionSoldState = {}));
var ProspectState;
(function (ProspectState) {
    ProspectState[ProspectState["open"] = 0] = "open";
    ProspectState[ProspectState["sold"] = 1] = "sold";
    ProspectState[ProspectState["notSold"] = 2] = "notSold";
})(ProspectState || (exports.ProspectState = ProspectState = {}));
var AuctionType;
(function (AuctionType) {
    AuctionType[AuctionType["single"] = 1] = "single";
    AuctionType[AuctionType["bundle"] = 2] = "bundle";
    AuctionType[AuctionType["prospect"] = 3] = "prospect";
    AuctionType[AuctionType["dutch"] = 4] = "dutch";
    AuctionType[AuctionType["spotbuy"] = 5] = "spotbuy";
})(AuctionType || (exports.AuctionType = AuctionType = {}));
var ReminderTypes;
(function (ReminderTypes) {
    ReminderTypes[ReminderTypes["PICKUP"] = 0] = "PICKUP";
    ReminderTypes[ReminderTypes["CONTRACT"] = 1] = "CONTRACT";
    ReminderTypes[ReminderTypes["PAYMENT"] = 2] = "PAYMENT";
    ReminderTypes[ReminderTypes["DEALER_DOCUMENTS"] = 3] = "DEALER_DOCUMENTS";
    ReminderTypes[ReminderTypes["AUCTION_STATUS"] = 4] = "AUCTION_STATUS";
})(ReminderTypes || (exports.ReminderTypes = ReminderTypes = {}));
// export interface IAuctionDetails<IDType = string> extends IAuction<IDType> {
//   // _visitors: [IUser];
//   // _starredBy: [IUser];
//   // startedBy: IUserMin;
// }
// export interface IAuctionCommon {
//   _id: string;
//   // highestBid: { type: Number; default: 0 };
//   highestBid: number;
//   auctionType: AuctionType;
//   // startedBy: { type: Schema.Types.ObjectId; ref: "User" }; // Referenz auf User der die Auktion gestartet hat
//   // createdBy: { type: Schema.Types.ObjectId; ref: "User" }; // Referenz auf User der die Auktion gestartet hat
//   startedBy: IUserMin; // Referenz auf User der die Auktion gestartet hat
//   createdBy: IUserMin; // Referenz auf User der die Auktion gestartet hat
//   createdAt: {
//     // Zeitpunkt an dem die Auktion gestartet wurde.
//     type: Date;
//   };
// }
// export interface IAuctionVirtuals {
//   id: string;
//   vin: string;
//   maker: string;
//   model: string;
// }
// export interface IAuctionBasisV2 {
//   bids: [
//     {
//       bidderId: string;
//       amount: number;
//       createdAt: Date;
//     }
//   ];
//   highestBidderId: string;
//   ownerOrgId: string;
//   auctionState: AuctionState;
//   car: string;
//   additionalCars: [string];
//   // car: { type: Schema.Types.ObjectId; ref: "Car" };
//   // additionalCars: [{ type: Schema.Types.ObjectId; ref: "Car" }];
//   visitors: [{ userId: string; isStarred: boolean }];
// }
// export interface IAuctionBasis {
// }
// export interface LegacyAuctionData extends OrgOwnedObjectContract<string> {
//   auctionEnd?: Date | null;
//   prospectDate?: Date | null;
//   dueDate?: number;
//   carId?: string;
//   carBundleIds?: string[];
//   targetPrice?: number;
//   reservePrice?: number;
//   priceRange?: number[];
//   stepAmount?: number;
//   durationInMillis?: number;
//   startPrice?: number;
//   spotBuyPrice?: number;
//   auctionType: AuctionType;
//   startedBy?: IUser;
//   ownerOrgId: string;
//   // ... other fields as required
// }
/*
 * ---------------------- ARGS ----------------------
 */
var AuctionListTypes;
(function (AuctionListTypes) {
    AuctionListTypes[AuctionListTypes["dealer"] = 0] = "dealer";
    AuctionListTypes[AuctionListTypes["account"] = 1] = "account";
    AuctionListTypes[AuctionListTypes["organization"] = 2] = "organization";
})(AuctionListTypes || (exports.AuctionListTypes = AuctionListTypes = {}));
exports.EXTRA_FEE_PERCENTAGE = 3;
exports.BUNDLE_PERCENTAGE = 3;
exports.PLATTFORM_FEE_PRICING_TABLE = [
    { fee: 145, start: 0 },
    { fee: 150, start: new Date(2021, 6, 1, 0, 0, 1).getTime() },
    { fee: 165, start: new Date(2022, 7, 1, 0, 0, 1).getTime() },
];
exports.BUNDLE_FEE_PRICING_TABLE = [
    { fee: 150, start: 0 },
    { fee: 180, start: new Date(2021, 6, 1, 0, 0, 1).getTime() },
];
exports.BUNDLE_MAX_FEE_PRICING_TABLE = [
    { fee: 3000, start: 0 },
    { fee: 3000, start: new Date(2021, 6, 1, 0, 0, 1).getTime() },
];
exports.BUNDLE_FROM_PRICING_TABLE = [
    { fee: 5000, start: 0 },
    { fee: 6000, start: new Date(2021, 6, 1, 0, 0, 1).getTime() },
];
exports.EXTRA_FEE_MAX_PRICING_TABLE = [{ fee: 500, start: 0 }];
exports.EXTRA_FEE_MIN_PRICING_TABLE = [{ fee: 100, start: 0 }];
exports.EXTRA_FEE_BELOW_500_PRICING_TABLE = [{ fee: 79, start: 0 }];
function isAuctionSchema(auction) {
    return "highestBid" in auction;
}
function getHighestBidHelper(auction, newBid) {
    var startPrice = auction.startPrice ? auction.startPrice : 0;
    if (newBid) {
        return newBid;
    }
    else if (auction.lastBid) {
        return auction.lastBid.amount !== startPrice ? auction.lastBid.amount : 0;
    }
    else if (isAuctionSchema(auction) && auction.highestBid) {
        return auction.highestBid !== startPrice ? auction.highestBid : 0;
    }
    else {
        return 0;
    }
}
function calculateAuctionFees(auction, orgSettings, newBid) {
    var _a;
    var fee = 0;
    var highestBid = getHighestBidHelper(auction, newBid);
    var extraFee = (orgSettings === null || orgSettings === void 0 ? void 0 : orgSettings.extraFee) ? legacyCalculateExtraFee(auction) : 0;
    if (auction.auctionType === AuctionType.bundle) {
        if (highestBid > 500 &&
            highestBid <= legacyGetFee(exports.BUNDLE_FROM_PRICING_TABLE, auction)) {
            fee = legacyGetFee(exports.BUNDLE_FEE_PRICING_TABLE, auction);
        }
        else if (highestBid > legacyGetFee(exports.BUNDLE_FROM_PRICING_TABLE, auction)) {
            fee = highestBid * (exports.BUNDLE_PERCENTAGE / 100);
            if (fee > legacyGetFee(exports.BUNDLE_MAX_FEE_PRICING_TABLE, auction)) {
                fee = legacyGetFee(exports.BUNDLE_MAX_FEE_PRICING_TABLE, auction);
            }
        }
    }
    else {
        if (highestBid && highestBid > 500) {
            fee = legacyGetFee(exports.PLATTFORM_FEE_PRICING_TABLE, auction);
        }
        if (auction.auctionEndTime > new Date(2024, 3, 1).getTime()) {
            if (highestBid > 1000) {
                var additionalFee = highestBid * (1 / 100);
                fee = Math.min(250, fee + additionalFee);
            }
        }
    }
    var tax = (fee + extraFee) * 0.19;
    var feeGross = fee + extraFee + tax;
    var total = highestBid + feeGross;
    return highestBid
        ? {
            fee: fee > 0 ? Number(fee.toFixed(2)) : undefined,
            tax: tax > 0 ? Number(tax.toFixed(2)) : undefined,
            feeGross: feeGross > 0 ? Number(feeGross.toFixed(2)) : undefined,
            extraFee: extraFee > 0 ? Number(extraFee.toFixed(2)) : undefined,
            total: Number(total.toFixed(2)),
            motorhammerCollectsFee: (_a = orgSettings === null || orgSettings === void 0 ? void 0 : orgSettings.doesNotCollectFee) !== null && _a !== void 0 ? _a : true,
        }
        : undefined;
}
function legacyGetFee(table, auction) {
    var e_1, _a;
    var fee = 0;
    var time = auction.auctionEndTime || new Date().getTime();
    try {
        for (var table_1 = __values(table), table_1_1 = table_1.next(); !table_1_1.done; table_1_1 = table_1.next()) {
            var entry = table_1_1.value;
            if (entry.start > time) {
                break;
            }
            fee = entry.fee;
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (table_1_1 && !table_1_1.done && (_a = table_1.return)) _a.call(table_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return fee;
}
function legacyCalculateExtraFee(auction) {
    // const highestBid =
    //   auction.highestBid !== auction.startBid ? auction.highestBid : 0;
    var highestBid = getHighestBidHelper(auction);
    if (highestBid > 500) {
        var minPrice = legacyGetFee(exports.EXTRA_FEE_MIN_PRICING_TABLE, auction);
        var maxPrice = legacyGetFee(exports.EXTRA_FEE_MAX_PRICING_TABLE, auction);
        var amountPercentage = highestBid * (exports.EXTRA_FEE_PERCENTAGE / 100);
        var amount = Math.max(minPrice, amountPercentage);
        return Math.min(amount, maxPrice);
    }
    else {
        return legacyGetFee(exports.EXTRA_FEE_BELOW_500_PRICING_TABLE, auction);
    }
}
