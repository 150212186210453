import { RoutingService } from "@emibee/lib-app-common";
import { AppRoutes } from "../common/AppRoutes";
import { LocalizationService } from "./LocalizationService";
import { ChangeScopeMH, MHClientService } from "./MHClientService";
import { environment } from "./environment";
import { AuctionDetailsDataKit } from "../data/auction/AuctionDetails";
import { CarListItemDataKit } from "../data/car/CarListItem";
import { UserOverviewDataKit } from "../data/user/UserOverview";
import { OrganizationType } from "@mh/common";
import { AuctionListItemDataKit } from "../data/auction/AuctionListItem";
import { buildDateRangeFetchMoreArgsFromPreset } from "../controls/view/useFetchMoreFilter";

export function setupClient() {
  const mhClient = new MHClientService(environment.graphServerUrl, environment.graphServerSubscriptionUrl);
  const rs = new RoutingService({ maxTrackingItems: 10 });
  // first register for security
  mhClient.registerService(rs);

  // register the routes
  rs.registerRoute(...AppRoutes);
  mhClient.registerService(new LocalizationService(mhClient));

  mhClient.observeChanges<ChangeScopeMH>(
    () => {
      // add global subscriptions
      if (
        mhClient.user?.account.ownerOrg?.id === mhClient.activeOrgId &&
        mhClient.user?.account.ownerOrg?.orgType === OrganizationType.other
      ) {
        // macht nur Sinn für MH, da dort alle permissions existieren (sollten)
        // sollte as mal andere Other-Organisationen geben müssen wir hier ran:
        mhClient.QueryManagementService.registerSubscription(
          AuctionDetailsDataKit.queries.pendingAuctionChanged,
          undefined,
          AuctionDetailsDataKit.queries.getAuctionDetails
        );
        mhClient.QueryManagementService.registerSubscription(CarListItemDataKit.queries.carChanged);
        mhClient.QueryManagementService.registerSubscription(UserOverviewDataKit.queries.accountChanged);
      } else if (mhClient.activeOrg) {
        // (re)-register subscriptions
        mhClient.QueryManagementService.registerSubscription(
          AuctionDetailsDataKit.queries.pendingAuctionChanged,
          {
            orgId: mhClient.activeOrg.id,
            includeChildOrgs: mhClient.activeOrg.isParentOrg
          },
          AuctionDetailsDataKit.queries.getAuctionDetails
        );
        mhClient.QueryManagementService.registerSubscription(CarListItemDataKit.queries.carChanged, {
          orgId: mhClient.activeOrg.id,
          includeChildOrgs: mhClient.activeOrg.isParentOrg
        });

        mhClient.prefetchWatchQuery({
          query: AuctionListItemDataKit.queries.getAuctionList,
          args: AuctionListItemDataKit.buildDateRangeFetchMoreArgs(
            "auctionEndTime",
            buildDateRangeFetchMoreArgsFromPreset({ pastDaysAndFuture: 90 }),
            {
              ownerOrgId: mhClient.activeOrgId,
              includeChildOrgs: mhClient.activeOrg?.isParentOrg
            }
          )
        });
      }
    },
    "LoginStatus",
    "ActingOrg"
  );

  return mhClient;
}
