import { buildTextResources4Enum } from "@emibee/lib-app-common";
import { CountryCode, Locale } from "@mh/common";

import { Domains, enumNamespace } from "../core/textResourceScopes";
import { environment } from "../core/environment";

// export enum CountryName {
//   Austria = "Österreich",
//   Germany = "Deutschland"
// }

export const CountryNameTextResources = buildTextResources4Enum<typeof CountryCode>({
  scope: "Data",
  namespace: enumNamespace(Domains.common, "CountryName"),
  resources: {
    // Austria: "Austria",
    // Germany: "Germany"
    AT: "Austria",
    DE: "Germany",
    PL: "Poland",
    CZ: "Czechia",
    HU: "Hungary",
    HR: "Croatia",
    SI: "Slovenia",
    SK: "Slovakia",
    RO: "Romania",
    FR: "France"
  }
});

export const LanguageEnumTextResources = buildTextResources4Enum<typeof Locale>({
  scope: "Data",
  namespace: enumNamespace(Domains.common, "Language"),
  resources: {
    English: "English",
    German: "German",
    Polish: "Polish",
    Czech: "Czech",
    Hungarian: "Hungarian",
    Romanian: "Romanian",
    French: "French"
  }
});

export enum MHImages {
  // All images should be placed in /public/img and in the s3 Bucket: s3://motorhammer/carbayUploads/pub_img/
  BANNER = "motorhammer-logo-fin01.svg",
  LOGO_AVATAR = "mh-hammer-orange-round.svg",
  LOGO_TEXT = "motorhammer-logo-white.png",
  EX_CAR_WIDGET = "car-list.png",
  MH_BLURRY_BACKGROUND = "blurry-soft-background.jpg",
  SETTINGS = "settings_header.png",
  GAVEL_SOLID = "gavel-solid.svg",
  EX_AUCTION_LIST_BIG = "auction_big.jpeg",
  EX_CAR_LIST_BIG = "car-list_big.jpg",
  CAR_SILHOUETTE = "car_silhouette.png",
  EX_AUCTION_WIDGET = "auction.png"
}

export enum PlaceholderImages {
  // Place all public images in the bucket: s3://motorhammer/carbayUploads/pub_img/
  CAR = "https://images.motorhammer.com/pub_img/image_placeholder.jpg"
}

export function getImageLocation(name: string): string {
  if (environment.name === "development") {
    // In development, return the path to the image in the public folder
    const returnVal = `/img/${name}`;
    return returnVal;
  } else {
    // In production, return the full URL to the image in S3
    const returnVal = `https://images.motorhammer.com/pub_img/${name}`;
    return returnVal;
  }
}

export enum LegacyRoutePaths {
  CAR_DETAILS = "/car-details/",
  AUCTION_DETAILS = "/auction/"
}
