import { buildTextResourcesMH, ViewNames, viewNamespace } from "../../core/textResourceScopes";

export const textResources = buildTextResourcesMH({
  scope: "Views",
  namespace: viewNamespace(ViewNames.car, "ExCarDetails"),
  resources: {
    detailsPageTitle: "Details",
    listPageTitle: "Cars",

    // moved to TileFields Enum
    // titleDataBlock: "Technical Data",
    // titleDealerBlock: "Dealer Info",
    // titleConditionBlock: "Condition",
    // titleEquipmentBlock: "Equipment",
    // titleQuickCarCheckBlock: "Quick Car Check",
    // titleAssessmentBlock: "Professional Car Check",
    // titleDatEquipmentBlock: "DAT Equipment",

    addImages: "Add Images",
    subTitleAddImages: "Add at least 5 images",
    // addImages: "Bilder upload",
    // subTitleAddImages: "Laden Sie mindestens 5 Bilder hoch",

    subTitleAssessmentBlock: "Nicht erforderlich, aber empfohlen.",

    nextStepsBlock: "Next Steps",
    actionsBlock: "Actions",

    //  titleInfoBlock: "{{maker}} {{model}} {{variant}}",
    vinOverline: "{{vin}}",
    carInfoTitle: "{{maker}} {{model}} {{variant}}",
    carInfoSubTitle: "{{info}}",
    subTitleInfoBlock: "{{vin}}",
    baseData: "Base Data",
    bottomData: "Bottom Info Data",

    imageCropDlgTitle: "Edit Image",
    docEditDlgTitle: "Edit Document",
    docEditDlgTxt: "Document Name",
    docEditDlgBtnShow: "Show Document",
    legacyImagesNotSupportedInfo: "Edit of legacy images is not supported.",

    actionAuction: "Auction",
    actionAuctionSecondaryText: "Start an auction",
    actionDeleteCar: "Delete",
    actionDeleteCarSecondaryText: "Delete this car",
    documentsTitle: "Documents",
    addImagesAndDocuments: "Add more Images & Documents",

    actionGotoAuction: "Goto Auction",
    actionGotoAuctionSecondaryText: "There is an active auction for this car.",

    dealerBlockInfoTextLegalHintTitle: "If selected, the following text will appear in the ad:",
    dealerBlockLegalHint:
      "A detailed assessment has not taken place. The vehicle was recorded without a lifting platform, so the underbody was not checked. Vehicles may have other defects due to wear and tear, depending on their mileage and age (e.g. defective battery, oil loss, paint defects, repainting, stone chips, etc.). Visual defects on dirty, wet, icy or snow-covered vehicles were recorded as best as possible, but it is possible that defects such as dents, scratches, etc. were overlooked due to the weather. Please take this into account when placing your bid."
  }
});
