"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allPrivilegesMH = exports.RwfPrivilegeMH = exports.UIPrivilegeMH = exports.SpecialPrivilegeMH = void 0;
var lib_shared_1 = require("@emibee/lib-shared");
var SpecialPrivilegeMH;
(function (SpecialPrivilegeMH) {
    SpecialPrivilegeMH["OrganizationApprove"] = "Organization.Approve";
    SpecialPrivilegeMH["AuctionBid"] = "Auction.Bid";
})(SpecialPrivilegeMH || (exports.SpecialPrivilegeMH = SpecialPrivilegeMH = {}));
var UIPrivilegeMH;
(function (UIPrivilegeMH) {
    UIPrivilegeMH["AppV2"] = "UI.AppV2";
})(UIPrivilegeMH || (exports.UIPrivilegeMH = UIPrivilegeMH = {}));
var RwfPrivilegeMH;
(function (RwfPrivilegeMH) {
    RwfPrivilegeMH["Car"] = "Car";
    RwfPrivilegeMH["Dealer"] = "Dealer";
    RwfPrivilegeMH["Auction"] = "Auction";
    RwfPrivilegeMH["TextResources"] = "TextResources";
    RwfPrivilegeMH["Administration"] = "Administration";
})(RwfPrivilegeMH || (exports.RwfPrivilegeMH = RwfPrivilegeMH = {}));
exports.allPrivilegesMH = [
    {
        privilege: SpecialPrivilegeMH.OrganizationApprove,
        type: lib_shared_1.PrivilegeType.special,
        group: lib_shared_1.PrivilegeGroup.Entities,
    },
    {
        privilege: UIPrivilegeMH.AppV2,
        type: lib_shared_1.PrivilegeType.special,
        group: lib_shared_1.PrivilegeGroup.UI,
    },
];
