import { ClientService, PageConcurrentPrefetchProps, buildRouteWithGraphPrefetch } from "@emibee/lib-app-common";
import { DateRangeFetchMoreArgs, RwfPermission } from "@emibee/lib-shared";
import { GetAuctionListArgs, RwfPrivilegeMH, SecurityAttributeScope } from "@mh/common";
import GavelIcon from "@mui/icons-material/Gavel";
import { ControlNames, Domains, controls, dataNamespace, defaults, domainView } from "../../core/textResourceScopes";
import {
  AuctionDetailsDataKit,
  AuctionListItemDataKit,
  IAuctionDetailsUI,
  IAuctionListItemUI
} from "../../data/auction";
import { AuctionDetailsTextResources as textResources } from "./textResources";
import { KeepAliveOwner, MHClientService } from "../../core/MHClientService";
import { buildDateRangeFetchMoreArgsFromPreset } from "../../controls/view/useFetchMoreFilter";

export interface ExAuctionDetailsConcurrentProps extends PageConcurrentPrefetchProps<IAuctionDetailsUI> {
  id: string;
}
export interface ExAuctionListConcurrentProps extends PageConcurrentPrefetchProps<IAuctionListItemUI[]> {
  auctionId?: string;
  filter?: string;
}

export const ExAuctionDetailsRoute = buildRouteWithGraphPrefetch({
  path: "/exAuctionDetails",
  displayText: textResources.title,
  icon: <GavelIcon />,
  auth: true,
  resources: {
    lazy: () => import("./ExAuctionDetailsPage"),
    localeNamespaces: [
      domainView(Domains.car),
      domainView(Domains.auction),
      defaults(),
      controls(ControlNames.common),
      dataNamespace(Domains.ui, "Formatters")
    ],
    prefetchGraph: (args: ExAuctionDetailsConcurrentProps | undefined, client: ClientService) => ({
      query: AuctionDetailsDataKit.queries.getAuctionDetails,
      args: { id: args?.id || "", securityScope: SecurityAttributeScope.max },
      // subscription: AuctionDetailsDataKit.queries.auctionChanged,
      // subArgs: { orgId: (client as MHClientService).activeOrgId },
      watchQuery: true

      // keepAlive: KeepAliveOwner.ExAuctionList
    })
  },
  params: ["id"],
  //   params: [""],
  privilege: RwfPrivilegeMH.Auction,
  permission: RwfPermission.read
});

export const ExAuctionListRoute = buildRouteWithGraphPrefetch<
  ExAuctionListConcurrentProps,
  IAuctionListItemUI[],
  DateRangeFetchMoreArgs<GetAuctionListArgs>,
  void
>({
  path: "/exAuctionList",
  icon: <GavelIcon />,
  displayText: textResources.listPageTitle,
  auth: true,
  resources: {
    lazy: () => import("./list/ExAuctionListPage"),
    localeNamespaces: [domainView(Domains.auction), defaults()],
    prefetchGraph: (_args, client) => ({
      query: AuctionListItemDataKit.queries.getAuctionList,
      // args: AuctionListItemDataKit.emptyFetchMoreArgs({
      //   ownerOrgId: (client as MHClientService).activeOrgId
      // }),
      args: AuctionListItemDataKit.buildDateRangeFetchMoreArgs(
        "auctionEndTime",
        buildDateRangeFetchMoreArgsFromPreset({ pastDaysAndFuture: 90 }),
        {
          ownerOrgId: (client as MHClientService).activeOrgId,
          includeChildOrgs: (client as MHClientService).activeOrg?.isParentOrg
        }
      ),
      keepAlive: KeepAliveOwner.ExAuctionList,
      watchQuery: true,
      cvFetch: true
    })
  },
  params: ["auctionId", "filter"],
  privilege: RwfPrivilegeMH.Auction,
  permission: RwfPermission.read
});
